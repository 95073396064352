/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


          var options = {
              // The Id, element or querySelector of the gallery widget:
              container: '#blueimp-gallery',
              // The tag name, Id, element or querySelector of the slides container:
              slidesContainer: 'div',
              // The tag name, Id, element or querySelector of the title element:
              titleElement: 'h3',
              // The class to add when the gallery is visible:
              displayClass: 'blueimp-gallery-display',
              // The class to add when the gallery controls are visible:
              controlsClass: 'blueimp-gallery-controls',
              // The class to add when the gallery only displays one element:
              singleClass: 'blueimp-gallery-single',
              // The class to add when the left edge has been reached:
              leftEdgeClass: 'blueimp-gallery-left',
              // The class to add when the right edge has been reached:
              rightEdgeClass: 'blueimp-gallery-right',
              // The class to add when the automatic slideshow is active:
              playingClass: 'blueimp-gallery-playing',
              // The class for all slides:
              slideClass: 'slide',
              // The slide class for loading elements:
              slideLoadingClass: 'slide-loading',
              // The slide class for elements that failed to load:
              slideErrorClass: 'slide-error',
              // The class for the content element loaded into each slide:
              slideContentClass: 'slide-content',
              // The class for the "toggle" control:
              toggleClass: 'toggle',
              // The class for the "prev" control:
              prevClass: 'prev',
              // The class for the "next" control:
              nextClass: 'next',
              // The class for the "close" control:
              closeClass: 'close',
              // The class for the "play-pause" toggle control:
              playPauseClass: 'play-pause',
              // The list object property (or data attribute) with the object type:
              typeProperty: 'type',
              // The list object property (or data attribute) with the object title:
              titleProperty: 'title',
              // The list object property (or data attribute) with the object URL:
              urlProperty: 'href',
              // The list object property (or data attribute) with the object srcset URL(s):
              srcsetProperty: 'urlset',
              // The gallery listens for transitionend events before triggering the
              // opened and closed events, unless the following option is set to false:
              displayTransition: true,
              // Defines if the gallery slides are cleared from the gallery modal,
              // or reused for the next gallery initialization:
              clearSlides: true,
              // Defines if images should be stretched to fill the available space,
              // while maintaining their aspect ratio (will only be enabled for browsers
              // supporting background-size="contain", which excludes IE < 9).
              // Set to "cover", to make images cover all available space (requires
              // support for background-size="cover", which excludes IE < 9):
              stretchImages: false,
              // Toggle the controls on pressing the Return key:
              toggleControlsOnReturn: false,
              // Toggle the controls on slide click:
              toggleControlsOnSlideClick: false,
              // Toggle the automatic slideshow interval on pressing the Space key:
              toggleSlideshowOnSpace: false,
              // Navigate the gallery by pressing left and right on the keyboard:
              enableKeyboardNavigation: true,
              // Close the gallery on pressing the ESC key:
              closeOnEscape: true,
              // Close the gallery when clicking on an empty slide area:
              closeOnSlideClick: true,
              // Close the gallery by swiping up or down:
              closeOnSwipeUpOrDown: true,
              // Emulate touch events on mouse-pointer devices such as desktop browsers:
              emulateTouchEvents: true,
              // Stop touch events from bubbling up to ancestor elements of the Gallery:
              stopTouchEventsPropagation: false,
              // Hide the page scrollbars:
              hidePageScrollbars: true,
              // Stops any touches on the container from scrolling the page:
              disableScroll: true,
              // Carousel mode (shortcut for carousel specific options):
              carousel: false,
              // Allow continuous navigation, moving from last to first
              // and from first to last slide:
              continuous: true,
              // Remove elements outside of the preload range from the DOM:
              unloadElements: true,
              // Start with the automatic slideshow:
              startSlideshow: false,
              // Delay in milliseconds between slides for the automatic slideshow:
              slideshowInterval: 5000,
              // The starting index as integer.
              // Can also be an object of the given list,
              // or an equal object with the same url property:
              index: 0,
              // The number of elements to load around the current index:
              preloadRange: 2,
              // The transition speed between slide changes in milliseconds:
              transitionSpeed: 400,
              // The transition speed for automatic slide changes, set to an integer
              // greater 0 to override the default transition speed:
              slideshowTransitionSpeed: undefined,
              // The event object for which the default action will be canceled
              // on Gallery initialization (e.g. the click event to open the Gallery):
              event: undefined,
              // Callback function executed when the Gallery is initialized.
              // Is called with the gallery instance as "this" object:
              onopen: undefined,
              // Callback function executed when the Gallery has been initialized
              // and the initialization transition has been completed.
              // Is called with the gallery instance as "this" object:
              onopened: undefined,
              // Callback function executed on slide change.
              // Is called with the gallery instance as "this" object and the
              // current index and slide as arguments:
              onslide: undefined,
              // Callback function executed after the slide change transition.
              // Is called with the gallery instance as "this" object and the
              // current index and slide as arguments:
              onslideend: undefined,
              // Callback function executed on slide content load.
              // Is called with the gallery instance as "this" object and the
              // slide index and slide element as arguments:
              onslidecomplete: undefined,
              // Callback function executed when the Gallery is about to be closed.
              // Is called with the gallery instance as "this" object:
              onclose: undefined,
              // Callback function executed when the Gallery has been closed
              // and the closing transition has been completed.
              // Is called with the gallery instance as "this" object:
              onclosed: undefined
          };

          // var indicatorOptions = {
          //     // The tag name, Id, element or querySelector of the indicator container:
          //     indicatorContainer: 'ol',
          //     // The class for the active indicator:
          //     activeIndicatorClass: 'active',
          //     // The list object property (or data attribute) with the thumbnail URL,
          //     // used as alternative to a thumbnail child element:
          //     thumbnailProperty: 'thumbnail',
          //     // Defines if the gallery indicators should display a thumbnail:
          //     thumbnailIndicators: false
          // };



          /*
           * Bootstrap wrapper for jQuery mmenu
           * Include this file after including the jquery.mmenu plugin for default Bootstrap tabs, pills and navbar support.
           */


          (function( $ ) {

              var _PLUGIN_ = 'mmenu',
                  _WRAPPR_ = 'bootstrap';


              //	Set some config
              $[ _PLUGIN_ ].configuration.classNames.selected = 'active';
              $[ _PLUGIN_ ].configuration.classNames.divider  = 'divider';


              //	Store original initMenu
              var orgInitMenu = $[ _PLUGIN_ ].defaults.initMenu || function() {};


              //	On initMenu, filter and refactor HTML for tabs, pills and navbars
              $[ _PLUGIN_ ].defaults.initMenu = function( $menu )
              {
                  orgInitMenu.call( this, $menu );

                  var _c = $[ _PLUGIN_ ]._c;

                  var _type = false,
                      types = [ 'nav-tabs', 'nav-pills', 'navbar-nav' ];

                  for ( var t = 0; t < types.length; t++ )
                  {
                      if ( $menu.children( '.' + types[ t ] ).length )
                      {
                          _type = types[ t ];
                          break;
                      }
                  }

                  if ( _type )
                  {
                      init.menu.call( this );
                      init.dropdown.call( this );
                      init[ _type.split( 'nav-' ).join( '' ).split( '-nav' ).join( '' ) ].call( this );
                  }
              };



              var init = {
                  menu: function()
                  {
                      this.$menu
                          .children()
                          .removeClass( 'nav' )
                          .find( '.sr-only' ).remove().end()
                          .find( '.divider:empty' ).remove();

                      var attrs = [ 'role', 'aria-haspopup', 'aria-expanded' ];
                      for ( var a = 0; a < attrs.length; a++ )
                      {
                          this.$menu.find( '[' + attrs[ a ] + ']' ).removeAttr( attrs[ a ] );
                      }
                  },
                  dropdown: function()
                  {
                      var $dropdown = this.$menu.find( '.dropdown' );

                      $dropdown
                          .removeClass( 'dropdown' );

                      $dropdown
                          .children( '.dropdown-toggle' )
                          .find( '.caret' ).remove().end()
                          .each(
                              function()
                              {
                                  $(this).replaceWith( '<span>' + $(this).html() + '</span>' );
                              }
                          );

                      $dropdown
                          .children( '.dropdown-menu' )
                          .removeClass( 'dropdown-menu' );
                  },
                  tabs: function()
                  {
                      this.$menu
                          .children()
                          .removeClass( 'nav-tabs' );
                  },
                  pills: function()
                  {
                      this.$menu
                          .children()
                          .removeClass( 'nav-pills' );
                  },
                  navbar: function()
                  {
                      this.$menu
                          .removeClass( 'collapse navbar-collapse' )
                          .wrapInner( '<div />' )
                          .children()
                          .addClass( 'mm-' + _WRAPPR_ + '-navbar' )
                          .children()
                          .removeClass( 'navbar-left navbar-right navbar-nav navbar-text navbar-btn' );

                      var that = this;

                      this.$orig
                          .closest( '.navbar' )
                          .find( '.navbar-header' )
                          .find( '.navbar-toggle' )
                          .off( 'click' )
                          .on( 'click.mm-' + _WRAPPR_ + '-navbar', function( e ) {
                              that.open();

                              e.stopImmediatePropagation();
                              e.preventDefault();
                          });
                  }
              };


          })( jQuery );



          $(document).ready(function() {



              $("#header-menu").mmenu({
                  // Options

                  "extensions": [
                      "pagedim-black"
                  ],

                  "setSelected": {
                      "hover": true,
                      "parent": true
                  },

                  "offCanvas": {
                      "position": "right",
                      pageSelector: ".wrap"
                  },

                  classNames: {
                      selected: "active"
                  }
              });



              var API = $("#header-menu").data( "mmenu" );

              $("#header-menu").click(function() {

                  API.open();
              });

              var $icon = $("#my-icon");


              $icon.on( "click", function() {
                  API.open();
              });

              API.bind( "opened", function() {
                  setTimeout(function() {
                      $icon.addClass( "is-active" );
                  }, 100);
              });
              API.bind( "closed", function() {
                  setTimeout(function() {
                      $icon.removeClass( "is-active" );
                  }, 100);
              });


          });




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired




      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'ons_werk': {
      init: function() {
        // JavaScript to be fired on the about us page
          document.getElementById('links').onclick = function (event) {
              event = event || window.event;
              var target = event.target || event.srcElement,
                  link = target.src ? target.parentNode : target,
                  options = {index: link, event: event},
                  links = this.getElementsByTagName('a');
              blueimp.Gallery(links, options);
          };

          // mixitup


          var containerEl = document.querySelector('.containermix');

          var mixer = mixitup(containerEl, {
              load: {
                  filter: document.location.hash === '' ? 'all' :
                      ('.' + document.location.hash.substring(1))
              }
          });




      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
